<template>
   <div class="row">
        <div class="col-md-12">
            <div class="input-group  fluid fc-search-bg">
                <input type="text" class="searchTerm" placeholder="Searching bar"/>
                <button class="tertiary fc-search-button"> <span class="icon-search"></span> </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Inconsolata:700');

input {
    font-family: 'Inconsolata';
}
.searchTerm {
  border: 2px solid #388E3C;
  border-right: none;
  margin-right: 0px;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.searchTerm:focus{
  color: blue;
}

.fc-search-bg{
    background-color: rgba(255,255,255,0.1) ;
}
.fc-search-button{
    margin-left: 0px ;
    border: 0px;
}

</style>