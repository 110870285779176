<template>
  <div class="dashboard-bg row">
     <div class="col-md-6"><SearchBar/></div>
      <div class="col-md-6">
        <button>Create</button>
      </div>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'Dashboard',
  components: { SearchBar},
}
</script>

<style scoped>
  .dashboard-bg{
    background-color: gray;
    height: 100vh;
}

</style>