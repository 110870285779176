import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import Dashboard from '@/views/Dashboard.vue'
import Electronics from '@/views/electronics/Electronics.vue'
import Programming from '@/views/programming/Programming.vue'
import About from '@/views/About.vue'
import ShowCases from '@/views/showCases/ticTacToe.vue'
import PageNotFound from '@/views/PageNotFound.vue'


const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/about'
  },
  {
    path:'/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/electronics',
    name: 'Electronics',
    component: Electronics
  },
  {
    path: '/programming',
    name: 'Programming',
    component: Programming
  },
  {
    path: '/showcases',
    name: 'ShowCases',
    component: ShowCases
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
