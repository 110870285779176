import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyB0jQ4ovdzOlrFZPnQvShARvOBG0QQseu4",
    authDomain: "zoafanel.firebaseapp.com",
    projectId: "zoafanel",
    storageBucket: "zoafanel.appspot.com",
    messagingSenderId: "955634336481",
    appId: "1:955634336481:web:8824ecada3b68a703eada0"
  };

// Init firebase

firebase.initializeApp(firebaseConfig)

// Init services

const projectFirestore = firebase.firestore() 
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// Timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, timestamp  }
