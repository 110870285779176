<template>
  <div class="row fc-navbar" >
    <div class="col-md-4 col-sm-9 ">
        <div class="fc-rounder">
          <router-link :to="{ name: 'Home'}" >
            <img src="@/assets/logo-3.png" alt="Fernando Cortes Nila" />
            </router-link>
          </div>
    </div>
    <div class="col-md-8 col-sm-12 fc-right hidden-sm">  
      <div class="fc-margin ">
          <router-link :to="{ name: 'About'}" >
            <button class="" :class="[isActive == 'About' ? 'inverse' : 'tertiary']" @click="fcSelected('About')">
              About
            </button> 
          </router-link>
          <router-link :to="{ name: 'ShowCases'}" class="">
            <button class="" :class="[isActive == 'ShowCases' ? 'inverse' : 'tertiary']" @click="fcSelected('ShowCases')"
            > Showcases
            </button> 
          </router-link>
          
      </div>
    </div>  
    <div class="col-md-8 col-sm-3 hidden-md hidden-lg ">  
      <div class="fc-margin">
          <label for="drawer-control" class="drawer-toggle fc-size-burger"></label>
          <input type="checkbox" id="drawer-control" class="drawer">
          <div class="fc-bg-dark">
            <label for="drawer-control" class="drawer-close fc-close-bg"></label>
                <h3> <router-link :to="{ name: 'About'}" > About </router-link> </h3>
                <h3> <router-link :to="{ name: 'ShowCases'}" > Show Cases </router-link> </h3>
            </div>
          </div>
    </div>  
  </div>


</template>

<script>
import { ref } from 'vue'

export default {
    setup(){
      const isActive = ref('About')

      const fcSelected = selection => {
        isActive.value = selection
      }

      return {isActive, fcSelected}
    }
}
</script>

<style scoped>
.fc-navbar{
    background: #388E3C;
}
.fc-rounder img{ 
    border-radius: 25px;
}
.fc-navbar img{
    padding-left: 12px;
    padding-top:8px ;
    max-height: 60px;
}
.fc-center{
  text-align: center;
}
.fc-right{
  text-align: right;
}
.fc-margin{
  padding-top:10px;
}

.fc-size-burger{
  color:#CCFF90;
  font-size: 1.6rem;
}
.fc-close-bg{
  background-color:white;
}
.fc-bg-dark{
  background-color:#263238;
}

.fc-bg-dark a{
  color:white;
}

</style>