<template>
  <div class="card fc-center">
      <img src="@/assets/fcortesnila.jpg" alt="Fernando Cortes Nila" class="fc-round">
        <h2 class="">Fernando Cortes Nila</h2>
        <p class="fc-margin-top">Electronics Engineer</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.fc-center{
  text-align: center;
}
.fc-margin-top{
    margin-top:-10px ;
}
.fc-round{
  border-radius: 150px;
}
.card{
  background-color: #388E3C;
  color:azure;
}

</style>