<template>
<div class="row">
  <div class="col-md-4"> </div>
  <div class="col-md-4">
    <div class="row">
      <div class="fc-frame"> 
        <form @submit.prevent="handleSubmit">
            <div class="col-md-12"><h3>Login</h3></div>
            <div class="col-md-12">
                <img src="@/assets/fcortesnila.jpg" alt="Fernando Cortes Nila" class="fc-rounder">
            </div>
            <div class="col-md-12">
                <div class="input-group">
                <input type="email" placeholder="Email" v-model="email">
                <input type="password" placeholder="Password" v-model="password">
                </div>
            </div>
            <div class="col-md-12">
                <div v-if="error" class="error"> {{error}}</div>
            <button v-if="!isPending" class="tertiary">Log in</button>
            <button v-if="isPending" disabled>Loading</button>
            </div>
        </form>
    </div>
  </div>
  </div>
</div>
    
</template>

<script>
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'
import { ref } from 'vue/'

export default {
    setup(){
        const router = useRouter()
        const email = ref('')
        const password = ref('')
        const {error, isPending,login} = useLogin()

        const handleSubmit = async () => {
            const res = await login(email.value, password.value)
            if(!error.value){
                console.log('User Logged in')
                router.push({ name: 'Dashboard'}) 
            }
        }

        return { email,password,handleSubmit, isPending, error}
    }
}
</script>
    <style>
    .fc-frame{
        text-align: center;
        border-radius: 40px;
        padding-top: 10vh ;
    }

    .fc-rounder{ 
        border-radius: 50px;
        height: 10vh;
    }

</style>