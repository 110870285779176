<template>
<div class="row wall">
  <div class="col-md-12">Banner</div>
  <div class="col-md-12">Programming</div>
  <div class="col-md-12">Electronics</div>
</div>
</template>

<script>
import PostList from '@/components/PostList.vue'

export default {
  name: 'Home',
  components: { PostList},
  setup(){


  }
}
</script>

<style>
.wall{
  color:azure;
}
</style>
