<template>
  Electronics
</template>

<script>
export default {

}
</script>

<style>

</style>