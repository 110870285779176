<template>
<div class="contenedor">
    <h1 class="notfound">404</h1>
    <h3>Sorry, the page your requested cannot be found or no longer exists.</h3>
</div>
</template>

<script>
export default {
     name: 'PageNotFound'

}
</script>

<style scoped>
.contenedor{
    text-align: center;
    margin-top: 50px;
}
.notfound{
    font-size: 10rem;
    padding-bottom: 30px ;
}
</style>