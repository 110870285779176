<template>
<div class="row">
  <div class="col-md-3">
    <Badge />
  </div>
  <div class="col-md-9">
    <div class="fc-padding-5"> 
      <label><h1 class="title">Welcome </h1></label>
      <div class="fc-content">
          <p>Let me tell you a little bit about myself. I completed my bachelor's degree at <a href="https://www.utm.mx/ing_electronica_eng.html" target="_blank" >Universidad Tecnologica de la Mixteca (UTM)</a> in Oaxaca, Mexico and I recently graduated from <a href="https://durhamcollege.ca/programs/electronics-engineering-technician-two-year" target="_blank">Durham College</a> in Ontario, Canada where I obtained an Ontario College Diploma in electronics. I have the pleasure of working in Mexican and Canadian companies, in person and remotely; from this I have gained both professional experience and great friendships. </p>
          <p>Born in Mexico in <a href="https://en.wikipedia.org/wiki/Oaxaca" target="_blank">Oaxaca state</a>, I spent my teenage years and young adult life in <a href="https://en.wikipedia.org/wiki/Huajuapan_de_Le%C3%B3n" target="_blank">Huajuapan de Leon</a>. My love and passion for electronic devices started right there. My first computer was a gift from my parents: a PC with 4Gb hard drive, an intel celeron processor, 216 Mb of memory ram with a windows 98 operating system. I spent lots of time and money from my part time job to upgrade its hardware and software. The curiosity and also frustration of making this computer faster pushed me to learn about software and hardware to the point of enrolling myself in an Electronics program in the local university <a href="https://www.utm.mx/ing_electronica_eng.html" target="_blank" >UTM</a>.</p>
          <p>In the second year of my program, I co-founded an internet-cafe business with my cousin Antonio, with the support and generous investment of our uncles. “Puntocom” started providing internet access, repairing computers and some coffee services in our neighbourhood (which back in the day did not have access to the internet). This experience helped me to develop a business sense and a good understanding of networking and radiotramssion (that is how we cover an area without internet access). </p>
          <p>Right before my graduation, I was granted a <a href="https://www.cic.gc.ca/english/work/iec/eligibility.asp" target="_blank" >Canadian working holiday visa</a> as part of the International Experience Canada program. I was allowed to travel and work in the country for a full year. In that time, I became familiar with the local culture and language. I worked in the food industry in different places until I got hired to work in a startup as a web developer, a job that I brought back to Mexico after my visa expired. I worked remotely for a whole year with them. I enjoyed my stay in Canada so much that I recently became a citizen, and  am ready now for a bold new journey.</p>
          <p>I am always interested in a challenge. Reach out to fcortesnila@gmail.com to connect!</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Badge from '@/components/Badge.vue'

export default {
  name: 'Home',
  components: { Badge},

}
</script>

<style scoped>

.fc-center{
  text-align: center;
}
.fc-content ,h1 ,h3 ,p ,a{
  color:azure;
}

.fc-content a{
  text-decoration: underline;
}
.fc-content p{
  margin-bottom: 10px;
}
.fc-padding-5{
  padding: 0px 5px 0px 7px;
}
label {
    display: inline-block;
    margin-top: 30px;
    position: relative;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
  }
  label::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #388E3C;
    position: absolute;
    z-index: -1;
    padding-right: 40px;
    left: -30px;
    transform: rotateZ(-1.5deg);
  }

</style>
