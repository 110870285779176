<template>
<div class="row">
  <div class="col-md fc-txt-white">
  <h1 class="fc-txt-center">Tic Tac Toe</h1>
    <div class="board" :class="[x_turn ? 'x':'circle']">
      <div class="cell" 
            v-for="cell in grid" 
            :key = "cell.id" 
            :class="cell.class"
            @click = "selection(cell.id)"></div>
    </div>
    <div class="fc-txt-center" v-if="gameOver">
      <h2> {{ message }} </h2>
      <!-- <div class="btn" ></div> -->
      <button class="tertiary" @click = "startGame">Restart</button>
    </div> 
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {

setup(){
     const grid = ref([])
     const message = ref('')
     const x_turn = ref(true)
     const gameOver = ref(false)
     let i = 0

    const startGame = () => {
      message.value = " "
      gameOver.value = false
      grid.value = []
      for (i = 0; i < 9; i++) {
          grid.value.push({id:i,class:' ', used:false},)
        }
      }

    startGame()

  const winCombinations = [
    [0, 1, 2],[3, 4, 5],[6, 7, 8],
    [0, 3, 6],[1, 4, 7],[2, 5, 8],
    [0, 4, 8],[2, 4, 6]
  ]

  const selection = index => {
    if(!grid.value[index].used && !gameOver.value){
        grid.value[index].used = true
        hoverClass(index)

        if( checkWin( grid.value[index].class )){
           printMessage(grid.value[index].class)  
           gameOver.value = true
          }
        else if(isDraw()) {
            printMessage("Draw")  
            gameOver.value = true
        }
        else{ 
          swapTurns()
        }
    }
  }

  const swapTurns = () => {
    x_turn.value = !x_turn.value
  }

  const printMessage = ( result ) => {
    switch (result) {
      case 'x':
            message.value = 'The winner is X!'
        break;
      case 'circle':
            message.value = 'The winner is O!'
        break;
      default:
            message.value = 'It is a draw!'
        break;
    }
  }

  const hoverClass = el => {
      if(x_turn.value){ grid.value[el].class='x' }
        else{ grid.value[el].class = 'circle'}
  }

  const isDraw = () => {
    return [...grid.value].every(cell => {
    return cell.class == 'circle' || cell.class == 'x' 
    })
  }

  const checkWin = (currentClass) => {
    return winCombinations.some(combination => {
      return combination.every(index => {
        return grid.value[index].class == currentClass
        })
      })
    }

  return{grid, selection, startGame, x_turn, message, gameOver}
}

}
</script>

<style scoped>

.fc-txt-center{
  text-align: center;
}
.fc-txt-white{
  color:white;
}

.board {
  height: 60vh;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(3, auto)
}

.cell {
  width: 100px;
  height: calc(100px * .9);
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.cell:first-child,
.cell:nth-child(2),
.cell:nth-child(3) {
  border-top: none;
}

.cell:nth-child(3n + 1) {
  border-left: none;
}

.cell:nth-child(3n + 3) {
  border-right: none;
}

.cell:last-child,
.cell:nth-child(8),
.cell:nth-child(7) {
  border-bottom: none;
}

.cell.x,
.cell.circle {
  cursor: not-allowed;
}

.cell.x::before,
.cell.x::after,
.cell.circle::before {
  background-color:lightgreen;
}

.board.x .cell:not(.x):not(.circle):hover::before,
.board.x .cell:not(.x):not(.circle):hover::after,
.board.circle .cell:not(.x):not(.circle):hover::before {
  background-color: #008000;
}

.cell.x::before,
.cell.x::after,
.board.x .cell:not(.x):not(.circle):hover::before,
.board.x .cell:not(.x):not(.circle):hover::after {
  content: '';
  position: absolute;
  width: calc(100px * .15);
  height: 100px;
}

.cell.x::before,
.board.x .cell:not(.x):not(.circle):hover::before {
  transform: rotate(45deg);
}

.cell.x::after,
.board.x .cell:not(.x):not(.circle):hover::after {
  transform: rotate(-45deg);
}

.cell.circle::before,
.cell.circle::after,
.board.circle .cell:not(.x):not(.circle):hover::before,
.board.circle .cell:not(.x):not(.circle):hover::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.cell.circle::before,
.board.circle .cell:not(.x):not(.circle):hover::before {
  width: 90px;
  height: 90px;
}

.cell.circle::after,
.board.circle .cell:not(.x):not(.circle):hover::after {
  width: calc(100px * .6);
  height: calc(100px * .6);
  background-color:#263238;
;
}

</style>