<template>
  <form @submit.prevent="handleSubmit">
      <h3>Sign up</h3>
      <input type="text" placeholder="Display Name" v-model="displayName">
      <input type="email" placeholder="Email" v-model="email">
      <input type="password" placeholder="Password" v-model="password">
      <div v-if="error" class="error"> {{error}}</div>
      <button v-if="!isPending">Sign up</button>
      <button v-if="isPending" disabled>Loading</button>
  </form>
</template>

<script>
import useSignup from '@/composables/useSignup'
import { useRouter } from 'vue-router'
import { ref } from 'vue/'

export default {
    setup(){
        const router = useRouter()
        const displayName = ref('')
        const email = ref('')
        const password = ref('')
        const {error, isPending,signup} = useSignup()

        const handleSubmit = async () => {
            const res = await signup(email.value, password.value, displayName.value)
            if(!error.value){
                console.log('User signup')
                router.push( { name: 'Dashboard'} )
            }
        }

        return { email,password,displayName ,handleSubmit, isPending, error}
    }
}
</script>

<style>

</style>