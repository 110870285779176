<template>
<div class="electronics">
  <h2>Electronics</h2>
</div> 
<div class="programming">
  <h2>Programming</h2>
</div> 
<div class="projects">
  <h2>Projects</h2>
</div> 

</template>

<script>
export default {

}
</script>

<style scoped>
h2{
  color:#aaa;
}
</style>